<style lang="less">
@import "./projectmenu.less";
</style>

<template>
  <div id="projectWrapper">
      <div  class="p-right-container">
        <router-view></router-view>
      </div>
  </div>
</template>

<script>
// import $ from "jquery";
import Service from "../../../libs/service.js";
import Cookies from "js-cookie";
import _ from "lodash";

export default {
  components: {},
  data() {
    return {
      viewerpath: "",
      routername: "",
      minVal: "248px",
      leftBarShrink: false,
      splitVal: "248px",
      title: "",
      projectId: null,
      workspaceId: null,
      projectInfo: {},
      workspaceInfo: {},
      pmsInfo: {},
      pmsId: null,
      encodePramas: "",
      roleId: null,
      userId: null,
    };
  },
  computed: {},
  methods: {
    initLocale () {
      let localLocales = {
        'x_view_in_dashboard': {
          'en-US': 'View in Dashboard',
          'zh-CN': '大屏查看',
          'zh-TW': '大屏查看'
        },
        'x_hide_sidebar': {
          'en-US': 'Hide sidebar',
          'zh-CN': '收起侧栏',
          'zh-TW': '收起側欄'
        }
      };

      let localeEN = this.$i18n.getLocaleMessage('en-US');
      let localeCN = this.$i18n.getLocaleMessage('zh-CN');
      let localeTW = this.$i18n.getLocaleMessage('zh-TW');

      for(let key in localLocales) {
        localeEN[key] = localLocales[key]['en-US'];
        localeCN[key] = localLocales[key]['zh-CN'];
        localeTW[key] = localLocales[key]['zh-TW'];
      }

      this.$i18n.setLocaleMessage('en-US', localeEN);
      this.$i18n.setLocaleMessage('zh-CN', localeCN);
      this.$i18n.setLocaleMessage('zh-TW', localeTW);
    },

    init() {

      this.roleId = Number(Cookies.get("access"));
      this.userId = Number(Cookies.get("userId"));

    },
    getPmsInfo() {
      var that = this;
      Service.request(
        "get",
        `/pms/album/info/${this.pmsId}`,
        "",
        function (dt) {
          that.pmsInfo = dt.data;
          that.$parent.navPaths = [
            {
              type: "break",
            },
            {
              type: "title",
              title: that.$t('x_project'),
              path: "projects"
            },
            {
              type: "break",
            },
            {
              type: "title",
              title: that.projectInfo.title,
              // path: "projects",
              path: "projectpmslist",
              query: {
                wid: that.workspaceId,
                prjid: that.projectId,
                p: that.encodePramas,
                prjedit: that.projectInfo.isEdit,
              },
            },
            {
              type: "break",
            },
            {
              type: "title",
              title: that.pmsInfo.title,
            },
          ];
        }
      );
    },
    toPath(url, p) {
      let hosts = window.location.host.split('.');
      if(Cookies.get('companyId')==42 && url=='projectdashboard'){
        url = 'hkpf_dashboard';

        let routeData = this.$router.resolve({
          name: url,
          query: {
            wid: this.workspaceId,
            prjid: this.projectId,
            p:this.encodePramas,
            prjedit: this.projectInfo.isEdit
          }
        });
        let targetUrl = routeData.href;
        window.open(targetUrl, "_blank");
        return;
      }

      if (this.$router.history.current.name == url) {
        return;
      }

      if (p == 0) {
        this.workspaceId = null;
        this.workspaceInfo = {};
        this.pmsId = null;
        this.pmsInfo = {};
      }
      if (url) {
        if(url == 'pmsview' || url == 'pmsmembers' || url == 'pmsspacesetting') {
          this.$router.push({
            name: url,
            query: {
              albumId: this.pmsId,
              layerId: this.pmsInfo.layerId,
              viewType: this.pmsInfo.view,
              prjid: this.projectId
            }
          });
          this.getPmsInfo();
        } else {
          this.$router.push({
            name: url,
            query: {
              wid: this.workspaceId,
              prjid: this.projectId,
              p: this.encodePramas,
              prjedit: this.projectInfo.isEdit,
            },
          });
        }
      }
    },
  },
  watch: {
    $route(to) {
      this.routername = to.name;
      if (_.startsWith(to.name, "project")) {
        this.workspaceId = null;
        this.pmsId = null;
        this.workspaceInfo = {};
        this.pmsInfo = {};
      }
    },
  },
  mounted() {
    this.initLocale();
    this.routername = this.$route.name;
    this.init();
  },
};
</script>
